import React, { createContext, useState, useEffect } from 'react';

const initialFields = [
    { id: '1', content: 'Strain Name (Text Field)', locked: true, type: 'Text' },
    { id: '2', content: 'Pressure (psi) (Number Field)', locked: true, type: 'Number' },
    { id: '3', content: 'Temperature (°C) (Number Field, supports negatives)', locked: true, type: 'Text' },
    { id: '4', content: 'Input Weight (Lbs) (Number Field)', locked: false, type: 'Number' },
    { id: '5', content: 'Operator (Dropdown)', locked: true, type: 'Dropdown' },
    { id: '6', content: 'Pressure 2 (psi) (Number Field)', locked: false, type: 'Number' },
    { id: '7', content: 'Temperature 2 (°C) (Number Field, supports negatives)', locked: false, type: 'Text' },
];

export const FieldsContext = createContext<any>(initialFields);

export const FieldsProvider = ({ children }: { children: React.ReactNode }) => {
    const tokens = localStorage.getItem('tokens');
    const customFields = tokens && JSON?.parse(tokens)?.company_custom_fields;
    const customFieldCompany = customFields && customFields?.length ? customFields : initialFields;
    const [fields, setFields] = useState<any>(customFields?.length > 0 ? initialFields.filter((field: any) =>!customFields?.some((selectedField: any) => selectedField.id === field.id)):initialFields.filter((field: any) =>!field.locked));
    const [selectedFields, setSelectedFields] = useState<any>(customFieldCompany.filter((field: any) => field.locked));

    useEffect(() => {
        const savedFields = localStorage.getItem('fields');
        const savedSelectedFields = localStorage.getItem('selectedFields');

        if (savedFields) setFields(JSON.parse(savedFields));
        if (savedSelectedFields) setSelectedFields(JSON?.parse(savedSelectedFields));
    }, []);

    useEffect(() => {
        localStorage.setItem('fields', JSON.stringify(fields));
        localStorage.setItem('selectedFields', JSON.stringify(selectedFields));
    }, [fields, selectedFields]);

    const addCustomField = (label: string, type: string) => {
        const newField = {
            id: Date.now().toString(),
            content: `${label} (${type} Field)`,
            locked: false,
            type: type,
        };
        setFields([...fields, newField]);
    };

    return (
        <FieldsContext.Provider
            value={{
                fields,
                setFields,
                selectedFields,
                setSelectedFields,
                initialFields,
                addCustomField,
            }}
        >
            {children}
        </FieldsContext.Provider>
    );
};