import {useEffect, useState} from 'react';
import jwtInterceptor from '../views/shared/jwtInterceptor';

// Define the type for a user
interface User {
	id: number;
	name: string;
	email: string;
	user_type: string;
	company: number;
	is_admin: boolean;
	applications: number[];
}

// current logged in user's comapny's users who have extracto application access
const useCurrentCompanyUsersWithAccessToExtracto = () => {
	const [currentCompanyUsersWithAccessToExtracto, setCurrentCompanyUsersWithAccessToExtracto] = useState<User[] | null>(null);
	
	useEffect(() => {
		const fetchCurrentCompanyUsersWithAccessToExtracto = async () => {
			try {
				const response = await jwtInterceptor.get<User[]>(`${process.env.REACT_APP_API_URL}/user/api/company-users/users_with_industrial_app/`);
				setCurrentCompanyUsersWithAccessToExtracto(response.data);
			} catch (err) {
				//console.error(err);
			}
		};
		
		fetchCurrentCompanyUsersWithAccessToExtracto();
	}, []);
	
	return currentCompanyUsersWithAccessToExtracto;
};

export default useCurrentCompanyUsersWithAccessToExtracto;