import { useEffect, useState } from 'react';
import jwtInterceptor from '../views/shared/jwtInterceptor';

// Define the type for a user profile
interface UserProfile {
    id: number;
    username: string;
    email: string;
    user_type: string;
    company: number;
    is_admin: boolean;
}

// Hook for fetching the current user's profile information
const useUserProfile = () => {
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await jwtInterceptor.get<UserProfile>(`${process.env.REACT_APP_API_URL}/user/profile/`);
                setUserProfile(response.data);
            } catch (err) {
                setError(`Failed to fetch user profile ${err}`);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, []);

    return { userProfile, loading, error };
};

export default useUserProfile;
