import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import { getApplications } from '../applications';

interface PayloadType {
    get: (key: string) => string;
}

interface User {
    id: number;
    name: string;
    email: string;
    isAdmin: boolean;
}

type SetUserType = (user: User | null) => void;
type NavigateType = (path: string) => void;

export const Login = async (payload: PayloadType, setUser: SetUserType, navigate: NavigateType) => {

    let toastId = toast.loading('Logging In...');

    try {
        const body = {
            email: payload.get('username'),
            password: payload.get('password'),
        };

        localStorage.setItem('UserEmail', payload.get('username') || '');
        const apiResponse = await axios.post(`${process.env.REACT_APP_API_URL}/user/login/`, body);
        localStorage.setItem('tokens', JSON.stringify(apiResponse.data));
        if(apiResponse?.data?.company_custom_fields?.length > 0 ){
            await localStorage.setItem('selectedFields', JSON.stringify(apiResponse?.data?.company_custom_fields));
        }
        if (apiResponse?.data?.user_name) {
            localStorage.setItem('UserName', apiResponse?.data?.user_name);
        } else {
            localStorage.setItem('UserName', payload.get('username') || '');
        }

        await getApplications();
        setUser(jwt_decode(apiResponse.data.token.access));

        toast.update(toastId, { render: 'Welcome!!', type: 'success', isLoading: false });
        setTimeout(() => {
            toast.dismiss(toastId);
            navigate('/Home');
        }, 1000);

    } catch (error: any) {
        console.log('error=>', error);
        if (error?.response?.data?.message) {
            toast.update(toastId, {
                render: `${error?.response?.data?.message}`,
                type: 'error',
                isLoading: false,
            });
        } else if (error?.response?.data?.errors) {
            let errorsArray = error?.response?.data?.errors;
            toast.update(toastId, {
                render: `${JSON.stringify(errorsArray.email[0])}`,
                type: 'error',
                isLoading: false,
            });
        } else {
            toast.update(toastId, {
                render: `${JSON.stringify(error)}`,
                type: 'error',
                isLoading: false,
          });
        }
        setTimeout(() => {
            toast.dismiss(toastId);
        }, 5000);
    }
};